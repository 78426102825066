import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';

import DownIcon from './img/down.png';
import UpIcon from './img/up.png';

const NavItem = ({
  label,
  link,
  initShow = true,
  list,
}: {
  label: string;
  link?: string;
  initShow?: boolean;
  list?: Array<{
    label: string;
    link: string;
    target?: string;
    rel?: string;
  }>;
}) => {
  const [show, setShow] = useState(initShow);
  return (
    <li key={label} className="relative mb-5 w-full">
      {link ? (
        <Link
          href={link}
          className="flex h-[46px] items-center justify-between font-PoppinsBold text-2xl"
        >
          <span className="text-xl">{label}</span>
        </Link>
      ) : (
        <h2
          onClick={() => setShow(!show)}
          className="flex h-[46px] items-center justify-between font-PoppinsBold text-2xl"
        >
          <span className="text-xl">{label}</span>
        </h2>
      )}
      {show && (
        <div className="flex flex-col">
          {list?.map(({ label, link, target, rel }) => {
            return (
              <Link
                key={link}
                className="mt-[10px] text-base leading-7 text-[#B2B2B2]"
                target={target || '_self'}
                href={link}
                rel={rel || ''}
              >
                {label}
              </Link>
            );
          })}
        </div>
      )}
      {
        // eslint-disable-next-line no-nested-ternary
        list && list.length > 0 ? (
          show ? (
            <Image
              src={DownIcon}
              onClick={() => setShow(!show)}
              className="absolute right-0 top-[0.5rem] h-[1.875rem] w-[1.875rem]"
              alt={$t({ defaultMessage: 'AirBrush AI Photo Editor', id: 'k4yS39' })}
            ></Image>
          ) : (
            <Image
              src={UpIcon}
              onClick={() => setShow(!show)}
              className="absolute right-0 top-[0.5rem] h-[1.875rem] w-[1.875rem]"
              alt={$t({ defaultMessage: 'AirBrush AI Photo Editor', id: 'k4yS39' })}
            ></Image>
          )
        ) : null
      }
    </li>
  );
};

export default ({ open }) => {
  const list = [
    {
      label: $t({ defaultMessage: 'Features', id: 'ZXp0z1' }),
      list: [
        {
          label: $t({ defaultMessage: 'Photo Retouch', id: 'oih2xB' }),
          link: '/retouch',
        },
        {
          label: $t({ defaultMessage: 'Photo Eraser', id: 'V7dlcl' }),
          link: '/eraser',
        },
        {
          label: $t({ defaultMessage: 'Image Background Remover', id: '0ZmpQh' }),
          link: '/removebg',
        },
        // {
        //   label: $t({ defaultMessage: 'Batch Editing', id: 'ek8EMd' }),
        //   link: '/batch',
        // },
      ],
    },
    {
      label: $t({ defaultMessage: 'Online Tools', id: 'NWcnUD' }),
      list: [
        {
          label: $t({ defaultMessage: 'Image Enhancer', id: 'cMS2CZ' }),
          link: '/image-enhancer',
        },
        {
          label: $t({ defaultMessage: 'AI Anime Generator', id: 'ljbBNd' }),
          link: '/ai-anime-generator',
        },
        {
          label: $t({ defaultMessage: 'Image Background Remover', id: '0ZmpQh' }),
          link: '/background-remover',
        },
        {
          label: $t({ defaultMessage: 'AI Image Extender', id: '1EAc8x' }),
          link: '/image-extender',
        },
        {
          label: $t({ defaultMessage: 'AI Video Enhancer', id: 'adMmvP' }),
          link: '/ai-video-enhancer',
        },
        {
          label: $t({ defaultMessage: 'Video Watermark Remover', id: 'DYutM7' }),
          link: '/video-watermark-remover',
        },
        {
          label: $t({ defaultMessage: 'Video Background Remover', id: 'NYF8vw' }),
          link: '/video-background-remover',
        },
        {
          label: $t({ defaultMessage: 'AI Object Remover', id: 'BzOsoZ' }),
          link: '/object-remover',
        },
      ],
    },
    {
      label: $t({ defaultMessage: 'Blog', id: 'tv5FG3' }),
      link: '/blog',
    },
    {
      label: $t({ defaultMessage: 'Explore', id: '7JlauX' }),
      initShow: false,
      list: [
        // {
        //   label: $t({ defaultMessage: 'Affiliate Program', id: '8tvGYT' }),
        //   link: '/partnership',
        //   rel: 'nofollow',
        // },
        {
          label: $t({ defaultMessage: 'Help Center', id: 'HtSDyc' }),
          target: '_blank',
          rel: 'nofollow',
          link: 'https://help.airbrush.com/hc/en-us',
        },
      ],
    },
    {
      label: $t({ defaultMessage: 'Download', id: '5q3qC0' }),
      link: '/tools/app',
    },
  ];

  return (
    <ul
      className={clsx(
        'w-full animate-[wiggle_1s_ease-in-out_infinite] overflow-y-scroll bg-black px-7 py-5 text-white',
        open ? 'block' : 'hidden'
      )}
      style={{
        height: 'calc(100vh - 112px)',
      }}
    >
      {list.map((item, index) => (
        <NavItem {...item} key={index} />
      ))}
    </ul>
  );
};
