import { COMMON } from 'constants/images';
import { COOKIE_LOCALE, LOCALE, COOKIE_MAX_AGE } from 'constants/common';
import CfImage from 'components/common/CfImage';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import clsx from 'clsx';
import Image from 'next/image';
import tiktok from 'assets/image/tiktok.svg';
import instagram from 'assets/image/ins.svg';
import facebook from 'assets/image/facebook.svg';
// import youtube from 'assets/image/youtube.svg';
// import pinterest from 'assets/image/pinterest.svg';
import twitter from 'assets/image/twitter.svg';
import appleIco from 'assets/image/apple.png';
import androidIco from 'assets/image/android.png';
import BlackDownload from 'components/common/BlackDownload';
import styles from './styles.module.scss';
import MobileMenu from './MobileMenu';

const Title = ({ text, titleStyle = {} }) => {
  return (
    <h3 className="mb-3 font-SemiBold" style={titleStyle}>
      {text}
    </h3>
  );
};

const PixocialText = () => {
  return (
    <div className="mt-5 lg:mt-10">
      <ReactSVG
        className="mb-5"
        src="https://gcs-airbrush.mr.pixocial.com/5abd6faadc74953988e32e88f78d68c9.svg"
      />
      <p className="text-sm text-[rgba(255,255,255,0.5)]">
        {$t({
          defaultMessage: 'The world leading tech company in digital content and GenAI.',
          id: 'q7ynps',
        })}
      </p>
    </div>
  );
};

const shareLink = {
  en: {
    facebook: 'https://www.facebook.com/airbrushappofficial',
    instagram: 'https://www.instagram.com/airbrushofficial/',
    // youtube: 'https://www.youtube.com/@airbrush_studio',
    pinterest: 'https://www.pinterest.com/airbrushofficial/',
    tiktok: 'https://www.tiktok.com/@airbrushofficial',
    twitter: 'https://twitter.com/AirBrushApp',
  },
};

export default () => {
  const router = useRouter();

  const updateLangCookie = useCallback((locale) => {
    Cookies.set(COOKIE_LOCALE, locale, { path: '/', expires: COOKIE_MAX_AGE });
  }, []);

  const getLangPath = useCallback(
    (locale) => {
      const localePath = locale === LOCALE.DEFAULT ? '' : `/${locale}`;
      let pathname = router.pathname;

      const featureUrls = ['/retouch', '/eraser', '/removebg', '/batch'];
      if (featureUrls.some((url) => pathname.startsWith(`/tools${url}`))) {
        pathname = pathname.replace('/tools', '');
      }

      if (/^\/blog/.test(pathname) && pathname === router.asPath) {
        // blog主页切换语言，到blog主页
        pathname = '/blog';
      } else if (/^\/blog/.test(pathname) && pathname !== router.asPath) {
        // blog文章页/category页切换语言，到对应blog文章页/category页
        pathname = `/blog/${router.asPath.split('/blog/')[1]}`;
      }

      let pathValue = `${localePath}${pathname}`;
      if (!pathValue) {
        pathValue = '/';
      }
      return pathValue;
    },
    [router.asPath, router.pathname]
  );

  const getCurrentUrl = useCallback(
    (platfrom) => {
      return shareLink?.[router.defaultLocale]?.[platfrom];
    },
    [router.defaultLocale]
  );

  const appleLink = useMemo(() => {
    return (
      <Link
        target="_blank"
        rel="nofollow"
        href="https://app.adjust.com/1bzav9iu"
        className="flex h-[60px] w-[230px] cursor-pointer items-center justify-start rounded-lg border border-solid border-[rgba(255,255,255,0.16)] from-ab-orange-1 to-ab-orange-2 px-6 py-3 text-white hover:border-[0px] hover:border-transparent lg:hover:bg-gradient-to-r"
      >
        <Image src={appleIco} alt="apple app icon" width={36} height={36} className="mr-4" />
        <p
          className="text-xs"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: $t(
              { defaultMessage: 'GET IT ON <br /><span>App Store</span>', id: 'RM/7Gk' },
              {
                span: (text) => {
                  return `<span class="text-xl">${text}</span>`;
                },
              }
            ),
          }}
        />
      </Link>
    );
  }, []);

  const androidLink = useMemo(() => {
    return (
      <Link
        target="_blank"
        href="https://app.adjust.com/1bzav9iu"
        // className="flex h-[60px] w-[230px] cursor-pointer items-center justify-center px-5 py-3 text-white"
        className="flex h-[60px] w-[230px] cursor-pointer items-center justify-start border border-solid border-[rgba(255,255,255,0.16)] from-ab-orange-1 to-ab-orange-2 px-6 py-3 text-white hover:border-[0px] hover:border-transparent lg:hover:bg-gradient-to-r"
        rel="nofollow"
        style={{
          borderRadius: '8px',
        }}
      >
        <Image src={androidIco} alt="android app icon" width={36} height={36} className="mr-4" />
        <p
          className="text-xs"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: $t(
              { defaultMessage: 'GET IT ON <br /><span>Google Play</span>', id: 'LFR8OO' },
              {
                span: (text) => {
                  return `<span class="text-xl">${text}</span>`;
                },
              }
            ),
          }}
        />
      </Link>
    );
  }, []);

  const toolsLink = useMemo(() => {
    return [
      { href: '/retouch', text: $t({ defaultMessage: 'AI Powered Retouch', id: 'vLC7P+' }) },
      { href: '/eraser', text: $t({ defaultMessage: 'Eraser', id: 'SvdI9p' }) },
      { href: '/removebg', text: $t({ defaultMessage: 'Background Remove', id: 'O/+QJQ' }) },
      // { href: '/batch', text: $t({ defaultMessage: 'Batch Editing', id: 'ek8EMd' }) },
    ];
  }, []);

  const workflows = useMemo(
    () => [
      {
        href: '/image-enhancer',
        text: $t({ defaultMessage: 'Image Enhancer', id: 'cMS2CZ' }),
      },
      {
        text: $t({ defaultMessage: 'AI Anime', id: 'MaHG6a' }),
        href: '/ai-anime-generator',
      },
      {
        text: $t({ defaultMessage: 'Image Background Remover', id: '0ZmpQh' }),
        href: '/background-remover',
      },
      {
        text: $t({ defaultMessage: 'AI Image Extender', id: '1EAc8x' }),
        href: '/image-extender',
      },
      {
        href: '/ai-video-enhancer',
        text: $t({ defaultMessage: 'AI Video Enhancer', id: 'adMmvP' }),
      },
      {
        text: $t({ defaultMessage: 'Video Watermark Remover', id: 'DYutM7' }),
        href: '/video-watermark-remover',
      },
      {
        text: $t({ defaultMessage: 'Video Background Remover', id: 'NYF8vw' }),
        href: '/video-background-remover',
      },
      {
        text: $t({ defaultMessage: 'AI Object Remover', id: 'BzOsoZ' }),
        href: '/object-remover',
      },
    ],
    []
  );

  const donwloadLink = useMemo(() => {
    return [
      {
        href: '/tools/app',
        text: $t({ defaultMessage: 'AirBrush Mobile', id: 'gYhCHv' }),
      },
      {
        href: '/desktop',
        text: $t({ defaultMessage: 'AirBrush Desktop', id: 'fD+7F3' }),
      },
    ];
  }, []);

  const moreLink = useMemo(() => {
    return [
      { href: '/blog', text: $t({ defaultMessage: 'Blog', id: 'tv5FG3' }) },
      // {
      //   href: '/partnership',
      //   noFollow: true,
      //   target: '_blank',
      //   text: $t({ defaultMessage: 'Affiliate Program', id: '8tvGYT' }),
      // },
      {
        href: 'https://help.airbrush.com/hc/en-us',
        noFollow: true,
        target: '_blank',
        text: $t({ defaultMessage: 'Help Center', id: 'HtSDyc' }),
      },
    ];
  }, []);

  const legalLink = useMemo(() => {
    return [
      {
        noFollow: true,
        href: '/legal/privacy-policy',
        text: $t({ defaultMessage: 'Privacy Policy', id: 'vx0nkZ' }),
      },
      {
        noFollow: true,
        href: '/legal/terms-of-service',
        text: $t({ defaultMessage: 'Terms of Service', id: '32rBNK' }),
      },
      {
        noFollow: true,
        href: '/legal/refund-policy',
        text: $t({ defaultMessage: 'Refund Policy', id: 'T4gmxU' }),
      },
      {
        noFollow: true,
        href: '/legal/cookies-policy',
        text: $t({ defaultMessage: 'Cookie Policy', id: 'cA0kQY' }),
      },
    ];
  }, []);

  const shareMenuLink = useMemo(() => {
    return [
      {
        href: getCurrentUrl('tiktok'),
        text: $t({ defaultMessage: 'TikTok', id: 'm5CvOs' }),
        url: tiktok,
      },
      {
        href: getCurrentUrl('instagram'),
        text: $t({ defaultMessage: 'Instagram', id: '39PtLD' }),
        url: instagram,
      },
      {
        href: getCurrentUrl('facebook'),
        text: $t({ defaultMessage: 'Facebook', id: 'EmpHyB' }),
        url: facebook,
      },
      // {
      //   href: getCurrentUrl('pinterest'),
      //   text: $t({ defaultMessage: 'Pinterest', id: '6aHfA7' }),
      //   url: youtube,
      // },
      {
        href: getCurrentUrl('twitter'),
        text: $t({ defaultMessage: 'Twitter', id: '8ywLSf' }),
        url: twitter,
      },
    ];
  }, [getCurrentUrl]);

  // const OPTIONS = useMemo(() => {
  //   if (router.pathname.includes('/blog')) {
  //     return LOCALE.OPTIONS.filter((item) => item.value !== 'de' && item.value !== 'ru');
  //   }
  //   return LOCALE.OPTIONS;
  // }, [router.pathname]);

  const OPTIONS = useMemo(() => {
    return LOCALE.OPTIONS;
  }, []);

  return (
    <div className={classNames('h-auto bg-black px-7 lg:h-[30rem]', styles.mediaStyle)}>
      <div className="m-auto flex h-full max-w-[1200px] flex-col text-white lg:flex-row">
        <div className="mr-0 w-full pt-10 lg:mr-[60px] lg:w-[245px] lg:flex-1">
          <div className="max-w-[285px]">
            <div className="mb-5 flex">
              <CfImage src={COMMON.logo} width={30} height={30} quality={100} alt="logo" />
              <p className="ml-4 text-2xl font-normal text-white">
                {$t({ defaultMessage: 'AirBrush', id: '47CPPy' })}
              </p>
            </div>
            <p
              className="mb-5 text-sm leading-normal opacity-50"
              style={{
                color: '#f6f6f6',
              }}
            >
              {$t({
                defaultMessage: 'The Best AI-Powered Portrait Editor for Creators.',
                id: 'eil84B',
              })}
            </p>
            <p
              className="mb-5 text-sm leading-normal"
              style={{
                color: '#f6f6f6',
              }}
            >
              <span className="opacity-50">
                {$t({ defaultMessage: 'For support', id: 'DHG62Z' })}
              </span>
              <br />
              <a
                className="text-[#FF813C] opacity-100"
                href="mailto:support@airbrush.com"
                target="_self"
                rel="noreferrer nofollow"
              >
                support@airbrush.com
              </a>
            </p>

            <div className="mb-5 flex justify-start">
              {shareMenuLink.map((item, index) => {
                return (
                  <Link
                    target="_blank"
                    href={item.href}
                    key={index}
                    className="mr-3"
                    title={item.text}
                    rel="nofollow"
                  >
                    <Image src={item.url} alt={item.text} width={23} height={23} unoptimized />
                  </Link>
                );
              })}
            </div>

            <div className=" group relative flex w-[183px] cursor-pointer items-center rounded-md border border-[#ffffff]/30 px-3.5 py-2.5">
              <div className=" relative h-7 w-7 min-w-[28px]">
                <CfImage
                  src={COMMON.lang}
                  width={28}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <div className=" ml-3 flex w-[118px] items-center justify-between text-base leading-normal">
                <span className="w-[6.25rem] overflow-hidden text-ellipsis whitespace-nowrap">
                  {LOCALE.MAP[router.locale]}
                </span>
                <div className=" relative h-4 w-4 transition duration-500 group-hover:rotate-180">
                  <CfImage
                    src={COMMON.arrow}
                    width={16}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </div>
              <div className=" absolute left-0 top-full hidden w-full pt-1 group-hover:block">
                <div className=" cursor-pointer rounded bg-[#1c1c1c] px-2.5">
                  {OPTIONS.map(({ label, value }) => {
                    return (
                      <div className=" relative w-full" key={value}>
                        {/* 这里不能用Link组件，会触发preload，导致修改lang cookie */}
                        <a
                          className={clsx(
                            ' block w-full text-base leading-[2.25]  hover:text-[#f6f6f6]',
                            router.locale === value ? 'text-[#f6f6f6]' : 'text-[#f6f6f6]/40'
                          )}
                          href={getLangPath(value)}
                          onClick={() => updateLangCookie(value)}
                          key={value}
                        >
                          {label}
                        </a>
                        {router.locale === value && (
                          <div className=" absolute right-2.5 top-2.5 h-4 w-4">
                            <CfImage
                              src={COMMON.checked}
                              width={16}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className=" hidden lg:block">
              <PixocialText></PixocialText>
            </div>
          </div>
        </div>

        <ul className="hidden pt-10 lg:flex">
          <li className="mr-[60px] flex w-[160px] flex-col">
            <Title text={$t({ defaultMessage: 'Features & Tools', id: 'FioieI' })} />
            {toolsLink.map((item, index) => {
              return (
                <Link
                  key={index}
                  href={item.href}
                  className="w-max-[100%] mb-3 inline-block text-sm text-[#7B7B7B] hover:text-ab-orange"
                >
                  {item.text}
                </Link>
              );
            })}
          </li>
          <li className="mr-[60px] flex w-[160px] flex-col">
            <Title text={$t({ defaultMessage: 'Online Tools', id: 'NWcnUD' })} />
            {workflows.map((item, index) => {
              return (
                <Link
                  key={index}
                  href={item.href}
                  className="w-max-[100%] mb-3 inline-block text-sm text-[#7B7B7B] hover:text-ab-orange"
                >
                  {item.text}
                </Link>
              );
            })}
          </li>
          <li className="mr-[60px] flex w-[160px] flex-col">
            <Title text={$t({ defaultMessage: 'Downloads', id: 'OnbqrE' })} />
            {donwloadLink.map((item, index) => {
              return (
                <Link
                  key={index}
                  href={item.href}
                  className="mb-3 inline-block w-max text-sm text-[#7B7B7B] hover:text-ab-orange"
                >
                  {item.text}
                </Link>
              );
            })}

            <Title
              text={$t({ defaultMessage: 'More', id: 'I5NMJ8' })}
              titleStyle={{ marginTop: '40px' }}
            />
            {moreLink.map((item, index) => {
              return (
                <Link
                  key={index}
                  href={item.href}
                  rel={item.noFollow ? 'nofollow' : undefined}
                  target={item.target || '_self'}
                  className="mb-3 inline-block w-max text-sm text-[#7B7B7B] hover:text-ab-orange"
                >
                  {item.text}
                </Link>
              );
            })}
          </li>
          <li className="flex w-[230px] flex-col">
            <Title text={$t({ defaultMessage: 'Get the App', id: '4muya6' })} />
            {/* <div className="mb-5">{appleLink}</div>
            {androidLink} */}
            <BlackDownload
              link="https://app.adjust.com/1bzav9iu"
              position="bottom_banner"
              mode="col"
            />
          </li>
        </ul>
        <ul className="flex w-full flex-col py-7 lg:hidden">
          <MobileMenu
            title={$t({ defaultMessage: 'Features & Tools', id: 'FioieI' })}
            lists={toolsLink}
          />
          <MobileMenu
            title={$t({ defaultMessage: 'Online Tools', id: 'NWcnUD' })}
            lists={workflows}
          />
          <MobileMenu
            title={$t({ defaultMessage: 'Downloads', id: 'OnbqrE' })}
            lists={donwloadLink}
          />
          <MobileMenu title={$t({ defaultMessage: 'More', id: 'I5NMJ8' })} lists={moreLink} />
          <MobileMenu title={$t({ defaultMessage: 'Legal', id: '7oFrM6' })} lists={legalLink} />

          {/* <MobileMenu
            title={$t({ defaultMessage: 'Social Media', id: '5bGX59' })}
            lists={shareMenuLink}
          /> */}
          <li className="mt-5">{appleLink}</li>
          <li className="mt-5">{androidLink}</li>
        </ul>

        <div className="lg:hidden">
          <PixocialText></PixocialText>
        </div>
      </div>
    </div>
  );
};
