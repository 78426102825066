import { MutableRefObject, useEffect } from 'react';

const useIntersectionObserver = ({
  ref,
  callback,
}: {
  ref: MutableRefObject<any>;
  callback: () => void;
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback?.();
          observer.disconnect();
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);
};

export default useIntersectionObserver;
