import downloadListURls from 'constants/download';
import Link from 'next/link';
import appleIco from 'assets/image/apple.png';
import androidIco from 'assets/image/android.png';
import Image from 'next/image';
import usePlatform from 'hooks/usePlatform';
import { downloadReportExposure, downloadReportClick } from 'utils/downloadReport';
import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import clsx from 'clsx';

const APPStore = ({ link, position }: { link: string; position?: string }) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useIntersectionObserver({
    ref,
    callback: () => {
      downloadReportExposure({
        position,
      });
    },
  });

  return (
    <Link
      ref={ref}
      href={link}
      rel="nofollow"
      target="_blank"
      // className="flex h-[60px] w-[220px] cursor-pointer items-center justify-center text-white lg:my-0 lg:mr-5"
      className="flex h-[60px] w-[230px] cursor-pointer items-center justify-start border border-solid border-[rgba(255,255,255,0.16)] bg-[#000] from-ab-orange-1 to-ab-orange-2 px-5 py-3 text-white hover:border-[0px] hover:border-transparent lg:my-0 lg:mr-5 lg:hover:bg-gradient-to-r"
      style={{
        borderRadius: '8px',
      }}
      onClick={() => {
        downloadReportClick({
          position,
        });
      }}
    >
      <Image src={appleIco} alt="apple app icon" width={48} height={48} className="mr-2" />
      <p className="text-xs">
        GET IT ON <br />
        <span className="text-xl">App Store</span>
      </p>
    </Link>
  );
};

const AndroidStore = ({ link, position }: { link: string; position?: string }) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useIntersectionObserver({
    ref,
    callback: () => {
      downloadReportExposure({
        position,
      });
    },
  });

  return (
    <Link
      href={link}
      target="_blank"
      rel="nofollow"
      // className="mt-5 flex h-[60px] w-[220px] cursor-pointer items-center justify-center text-white lg:my-0 lg:mr-5"
      className="mt-5 flex h-[60px] w-[230px] cursor-pointer items-center justify-start border border-solid border-[rgba(255,255,255,0.16)] bg-[#000] from-ab-orange-1 to-ab-orange-2 px-5 py-3 text-white hover:border-[0px] hover:border-transparent hover:bg-gradient-to-r lg:my-0 lg:mr-5"
      style={{
        borderRadius: '8px',
      }}
      onClick={() => {
        downloadReportClick({
          position,
        });
      }}
    >
      <Image src={androidIco} alt="android app icon" width={48} height={48} className="mr-2" />
      <p className="text-xs">
        GET IT ON <br />
        <span className="text-xl">Google Play</span>
      </p>
    </Link>
  );
};

const BlackDownload = ({
  link,
  position,
  mode,
}: {
  link: string;
  position?: string;
  mode?: string;
}) => {
  const { platform } = usePlatform();

  if (platform === 'iOS') {
    return (
      <div className="my-5">
        <APPStore link={link} position={position} />
      </div>
    );
  }

  if (platform === 'Android') {
    return (
      <div className="my-5">
        <AndroidStore link={link} position={position} />
      </div>
    );
  }

  return (
    <div
      className={clsx('my-5 grid grid-rows-2 gap-4 lg:grid-cols-2')}
      style={
        mode
          ? {
              gridTemplateColumns: '1fr',
            }
          : {}
      }
    >
      <APPStore link={downloadListURls.ios} position={position} />
      <AndroidStore link={downloadListURls.android} position={position} />
    </div>
  );
};

export default BlackDownload;
